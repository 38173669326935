/**
 * Shared: Components / Form / Tools > Native form validation
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	noop
} from '../../../utils/index';
import {scrollTop} from '../../../utils/scroll';

import SelectorEngine from '../../../dom/selector-engine';
import EventHandler   from '../../../dom/event-handler';
import Manipulator    from '../../../dom/manipulator';

import BaseClass from '../../../utils/base-class';

/**
 * @type {string}
 */
const NAME = 'nfv';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	scrollToInvalidField: true,
	onInit               : noop, // (event) => { console.log('onInit', event); },
	onValidationFailed   : noop, // (event) => { console.log('onValidationFailed', event); },
	onValidationSuccess  : noop //(event) => { console.log('onValidationSuccess', event); }
};

/**
 *  Class
 */
class NativeFormValidation extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = NativeFormValidation.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this._setup();
	}

	/**
	 * API-methode ´getForm()´.
	 */
	getForm() {
		return this._element;
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		EventHandler.on(this._element, 'submit.ifab', event => {
			this._checkStatus();

			// Speichere aktuellen Validierungsstatus.
			this.validity = this._element.checkValidity();

			Manipulator.addClass(this._element, 'was-validated');

			// Formsubmit bei negativer Validierung verhindern.
			if (!this.validity) {
				event.preventDefault();
				event.stopPropagation();

				// Handling `invalid` Felder & Fieldsets.
				this._handleInvalid();

				Manipulator.removeClass(this._element, 'was-validated-valid');
				Manipulator.addClass(this._element, 'was-validated-invalid');
			} else {
				Manipulator.removeClass(this._element, 'was-validated-invalid');
				Manipulator.addClass(this._element, 'was-validated-valid');
			}

			// Handling `valid` Felder & Fieldsets.
			this._handleValid();
		});

		const eventInit = EventHandler.trigger(this._element, 'onInit.ifab');

		execute(
			this._config.onInit,
			eventInit
		);
	}

	_checkStatus() {
		this.invalidFieldsets = SelectorEngine.find('fieldset:invalid', this._element);
		this.validFieldsets   = SelectorEngine.find('fieldset:valid', this._element);
		this.invalidFields    = SelectorEngine.find(':invalid:not(fieldset)', this._element);
		this.validFields      = SelectorEngine.find(':valid:not(fieldset)', this._element);
	}

	_handleInvalid() {
		// Felder.
		if (this.invalidFields.length > 0) {
			for (const field of this.invalidFields) {
				// Custom-Event triggern.
				EventHandler.trigger(field, 'validationFailed.ifab');
			}

			if (this._config.scrollToInvalidField) {
				const label = SelectorEngine.findOne(`label[for="${this.invalidFields[0].id}"]`);

				if (label) {
					scrollTop(label).then(element => {
						// Do nothing...
					});
					// label.scrollIntoView({
					// 	left    : 0,
					// 	block   : 'start',
					// 	behavior: 'smooth'
					// });
				} else {
					scrollTop(this.invalidFields[0]).then(element => {
						// Do nothing...
					});
					// this.invalidFields[0].scrollIntoView({
					// 	left    : 0,
					// 	block   : 'start',
					// 	behavior: 'smooth'
					// });
				}
			}
		}

		// Fieldsets.
		if (this.invalidFieldsets.length > 0) {
			for (const fieldset of this.invalidFieldsets) {
				// Custom-Event triggern.
				EventHandler.trigger(fieldset, 'validationFailed.ifab');
			}
		}

		if (!this.validity) {
			const params = {
				form     : this._element,
				fieldsets: this.invalidFieldsets,
				fields   : this.invalidFields
			};

			// Custom-Failed-Event des Formulares triggern.
			const eventInvalid = EventHandler.trigger(this._element, 'validationFailed.ifab', params);

			execute(
				this._config.onValidationFailed.bind(params),
				eventInvalid
			);
		}
	}

	_handleValid() {
		// Felder.
		if (this.validFields.length > 0) {
			for (const field of this.validFields) {
				// Custom-Event triggern.
				EventHandler.trigger(field, 'validationSuccess.ifab');
			}
		}

		// Fieldsets.
		if (this.validFieldsets.length > 0) {
			for (const fieldset of this.validFieldsets) {
				// Custom-Event triggern.
				EventHandler.trigger(fieldset, 'validationSuccess.ifab');
			}
		}

		if (this.validity) {
			const params = {
				form : this._element
			};

			const eventValid = EventHandler.trigger(this._element, 'validationSuccess.ifab', params);

			execute(
				this._config.onValidationSuccess.bind(params),
				eventValid
			);
		}
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default NativeFormValidation;
