/**
 * Prins: Components / Switch view
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {getElementFromSelector} from '../../../../shared/utils';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'switch-view';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const getViews = (key, controls, target) => {
	const currentStorageView = localStorage.getItem(`${window.Prins.prefix}${NAME}-${key}`);

	let data = {
		current: '',
		views  : {}
	};

	let currentControlView = '';

	// Verfügbare ´Views´ von den Buttons auslesen.
	for (const control of controls) {
		data.views[control.dataset.view] = `-view-${control.dataset.view}`;

		if (Manipulator.getAria(control, 'pressed') === true) {
			currentControlView = control.dataset.view;
		}
	}

	const viewKeys    = Object.keys(data.views);
	const viewClasses = Object.values(data.views);

	if (currentStorageView && viewKeys.indexOf(currentStorageView) >= 0) {
		data.current = currentStorageView;
	} else if (currentControlView && viewKeys.indexOf(currentControlView) >= 0) {
		data.current = currentControlView;
	} else {
		for (let c in viewClasses) {
			if (!data.current && target.classList.contains(c)) {
				data.current = currentControlView;
			}
		}

		if (!data.current) {
			data.current = viewKeys[0];
		}
	}

	return data;
};

const bindDropdown = (element) => {
	let dropdown = (element.dataset.type === 'dropdown') ? SelectorEngine.parents(element, `[data-${NAME}-dropdown]`)[0] : null;

	if (dropdown) {
		EventHandler.on(dropdown, `updateDropdown${EVENT_KEY}`, (event) => {
			const switchIcon = SelectorEngine.findOne('[data-icon]', event.switchToControl);
			const dropdownIcon = SelectorEngine.findOne('[data-bs-toggle="dropdown"] [data-icon]', event.target);

			if (switchIcon && dropdownIcon) {
				dropdownIcon.setAttribute('class', switchIcon.getAttribute('class'));
			}
		});
	}

	Data.set(element, `${DATA_KEY}.dropdown`, dropdown);
};

// ------
// Public
// ------

const render = (element) => {
	// Wurde Container schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const controls = SelectorEngine.find('[data-view]', element);
	const target   = getElementFromSelector(element);

	if (target && controls.length > 0) {
		bindDropdown(element);

		const key  = element.dataset.key ?? target.id;
		const data = getViews(key, controls, target);

		Data.set(element, `${DATA_KEY}.classes`, Object.values(data.views));
		Data.set(element, `${DATA_KEY}.controls`, controls);
		Data.set(element, `${DATA_KEY}.key`, key);
		Data.set(element, `${DATA_KEY}.target`, target ?? null);

		// Handling ´switch view´.
		EventHandler.on(element, `switch${EVENT_KEY}`, (event) => {
			const classes = Data.get(event.target, `${DATA_KEY}.classes`).join(' ');
			const dropdown    = Data.get(event.target, `${DATA_KEY}.dropdown`);
			const controls    = Data.get(event.target, `${DATA_KEY}.controls`);
			const key         = Data.get(event.target, `${DATA_KEY}.key`);
			const target      = Data.get(event.target, `${DATA_KEY}.target`);

			const setClass = data.views[event.switchToView];
			const childs   = SelectorEngine.children(target, '*');

			// Kontrollelement aktualisieren.
			for (const control of controls) {
				if (control.dataset.view === event.switchToView) {
					Manipulator.addClass(control, '_active');
					Manipulator.setAria(control, 'pressed', true);

					if (dropdown) {
						EventHandler.trigger(dropdown, `updateDropdown${EVENT_KEY}`, {
							switchToControl : control
						});
					}
				} else {
					Manipulator.removeClass(control, '_active');
					Manipulator.setAria(control, 'pressed', false);
				}
			}

			// Zielelement aktualisieren.
			Manipulator.removeClass(target, classes);
			Manipulator.addClass(target, setClass);

			for (const child of childs) {
				Manipulator.removeClass(child, classes);
				Manipulator.addClass(child, setClass);
			}

 			localStorage.setItem(`${window.Prins.prefix}${NAME}-${key}`, event.switchToView);
		});

		// Button click.
		EventHandler.on(element, `click${EVENT_KEY}`, `.${NAME}__control`, (event) => {
			event.preventDefault();

			if (!event.target.classList.contains('_active')) {
				EventHandler.trigger(element, `switch${EVENT_KEY}`, {
					switchToView: event.target.dataset.view
				});
			}
		});

		// Initialer Switch.
		EventHandler.trigger(element, `switch${EVENT_KEY}`, {
			switchToView: data.current
		});
	} else if (controls.length > 0) {
		for (const control of controls) {
			Manipulator.setDisabled(control, true);
		}
	}




	// if (target && controls.length > 0) {
	// 	// Key auf Basis Elemenbt-ID setzen wenn nicht übergeben.
	// 	if (!key) {
	// 		key = target.getAttribute('id');
	// 	}
	//
	// 	// Im Modus ´Dropdown´?
	// 	const parentDropdown = SelectorEngine.parents(element, `[data-${NAME}-dropdown]`);
	//
	// 	if (parentDropdown) {
	// 		EventHandler.on(parentDropdown, `updateIcon${EVENT_KEY}`, (event) => {
	// 			console.log('updateIcon', event);
	// 		});
	// 	}
	//
	// 	Data.set(element, `${DATA_KEY}.dropdown`, parentDropdown ?? null);
	// 	Data.set(element, `${DATA_KEY}.target`, target ?? null);
	// 	Data.set(element, `${DATA_KEY}.key`, key ?? target.getAttribute('id'));
	//
	// 	const childs      = SelectorEngine.children(target, '*');
	// 	const data        = getViews(key, controls, target);
	// 	// const viewKeys    = Object.keys(data.views);
	// 	const viewClasses = Object.values(data.views);
	//
	// 	// Handling ´switch view´.
	// 	EventHandler.on(element, `switchView${EVENT_KEY}`, (event) => {
	// 		console.log(event);
	// 		const classRemove = viewClasses.join(' ');
	// 		const classAdd    = data.views[event.view];
	// 		const dropdown    = Data.get(element, `${DATA_KEY}.dropdown`);
	// 		const target      = Data.get(element, `${DATA_KEY}.target`);
	// 		const key         = Data.get(element, `${DATA_KEY}.key`);
	// 		const childs      = SelectorEngine.children(target, '*');
	//
	// 		for (const control of controls) {
	// 			if (control.dataset.view === event.view) {
	// 				Manipulator.addClass(control, '_active');
	// 				Manipulator.setAria(control, 'pressed', true);
	//
	// 				if (dropdown) {
	// 					EventHandler.trigger(dropdown, `updateIcon${EVENT_KEY}`, {
	// 						view: event.delegateTarget.dataset.view
	// 					});
	// 				}
	// 			} else {
	// 				Manipulator.removeClass(control, '_active');
	// 				Manipulator.setAria(control, 'pressed', false);
	// 			}
	// 		}
	//
	// 		Manipulator.removeClass(target, classRemove);
	// 		Manipulator.addClass(target, classAdd);
	//
	// 		for (const child of childs) {
	// 			Manipulator.removeClass(child, classRemove);
	// 			Manipulator.addClass(child, classAdd);
	// 		}
	//
 	// 		localStorage.setItem(`${window.Prins.prefix}switch-view-${key}`, event.view);
	// 	});
	//
	// 	// Buttonclick.
	// 	EventHandler.on(element, `click${EVENT_KEY}`, '[data-view]', (event) => {
	// 		event.preventDefault();
	//
	// 		if (!event.delegateTarget.classList.contains('_active')) {
	// 			// EventHandler.trigger
	// 			EventHandler.trigger(element, `switchView${EVENT_KEY}`, {
	// 				view: event.delegateTarget.dataset.view
	// 			});
	// 		}
	// 	});
	//
	// 	// Initialer Switch.
	// 	EventHandler.trigger(element, `switchView${EVENT_KEY}`, {
	// 		view: data.current
	// 	});
	// } else if (controls.length > 0) {
	// 	for (const control of controls) {
	// 		Manipulator.setDisabled(control, true);
	// 	}
	// }

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

/**
 * ´Record group´-Elemente initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`[data-${NAME}]`);

	for (const element of collection) {
		group.push(render(element));
	}

	return group;
};

// Export
export default {
	init: init
};
